import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import Section from "../../common/sections/section.component"
import { ChosenStories } from "./blog.interface"
import FeaturedStoriesSlider from "./featured-stories-slider"

const FeaturedStoriesSection = styled(Section)`
  margin-bottom: 70px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    margin-bottom: 40px;
  }
`

const BlogChosenStories: AppFunctionComponent<ChosenStories> = ({
  featuredPosts,
}) => (
  <FeaturedStoriesSection>
    <FeaturedStoriesSlider posts={featuredPosts} />
  </FeaturedStoriesSection>
)

export default BlogChosenStories
