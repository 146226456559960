import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Tag from "../../common/tag.component"
import { BlogTag } from "./blog.interface"
import { useQueryFilters } from "../../../helpers/filter-tags"
import { AppFunctionComponent, MultipleQueryResult } from "../../../types"
import flattenQueryResult from "../../../helpers/flatten-query-result"

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
`

const BlogTagElement = styled(Tag)`
  margin-bottom: 5px;
`
interface Tags {
  readonly tags: BlogTag[]
  readonly activeTags: BlogTag["slug"][]
  readonly onTagActivation: (slug: BlogTag["slug"]) => void
}

interface TagItem {
  slug: string
  text: string
  readonly onTagActivation: (slug: BlogTag["slug"]) => void
  readonly activeTags: BlogTag["slug"][]
}

const TagListItem: AppFunctionComponent<TagItem> = ({
  slug,
  text,
  onTagActivation,
  activeTags,
}) => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    setActive(
      activeTags.includes(slug) || (activeTags.length === 0 && slug === "")
    )
  }, [slug, activeTags])
  const activateTag = (): void => onTagActivation(slug)
  return (
    <BlogTagElement key={slug} onClick={activateTag} active={active}>
      {text}
    </BlogTagElement>
  )
}

const TagsList: AppFunctionComponent<Tags> = ({
  tags,
  activeTags,
  onTagActivation,
}) => {
  const tagsList = tags
  return (
    <TagsContainer>
      {tagsList.map(({ slug, text }) => {
        return (
          <TagListItem
            key={slug}
            activeTags={activeTags}
            slug={slug}
            onTagActivation={onTagActivation}
            text={text}
          />
        )
      })}
    </TagsContainer>
  )
}
interface Props {
  basePath: string
  oneFilterAtTime?: boolean
}

const BlogFilters: AppFunctionComponent<Props> = ({
  basePath,
  oneFilterAtTime = false,
}) => {
  const { content }: MultipleQueryResult<BlogTag> = useStaticQuery(query)
  const { resolveTag, activeTags } = useQueryFilters(basePath, oneFilterAtTime)

  const allTag = {
    slug: "",
    text: "All",
  }

  const resolvedTags = [
    ...(oneFilterAtTime ? [allTag] : []),
    ...flattenQueryResult<BlogTag>(content),
  ]
  return (
    <TagsList
      tags={resolvedTags}
      onTagActivation={resolveTag}
      activeTags={activeTags}
    />
  )
}

export default BlogFilters

const query = graphql`
  query BlogTagsQuery {
    content: allContentfulTag(
      filter: { type: { in: ["common", "blog"] }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          slug
          text
          type
        }
      }
    }
  }
`
