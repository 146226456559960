import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import RichText from "../../../resolvers/rich-text/rich-text.resolver"
import { AppFunctionComponent, SectionQueryResult } from "../../../types"
import { PrimaryHeading } from "../../common/heading.component"
import StandardSection from "../../common/sections/section.component"

const BlogHeadingSection = styled(StandardSection)`
  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    margin-bottom: -40px;
  }
`
const TextContainer = styled.div`
  max-width: 760px;
`

const BlogInfo: AppFunctionComponent = () => {
  const { content }: SectionQueryResult = useStaticQuery(query)
  const { title, text } = content
  return (
    <BlogHeadingSection>
      <PrimaryHeading renderAs={"h1"}>{title}</PrimaryHeading>
      {text && (
        <TextContainer>
          <RichText content={text} />
        </TextContainer>
      )}
    </BlogHeadingSection>
  )
}

export default BlogInfo

const query = graphql`
  query BlogInfoSection {
    content: contentfulSection(contentfulid: { eq: "blog" }) {
      title
      text {
        raw
      }
    }
  }
`
