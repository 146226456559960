import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SecondaryHeading } from "../components/common/heading.component"
import Section from "../components/common/sections/section.component"
import BlogChosenStories from "../components/community/blog/blog-chosen-stories"
import BlogInfo from "../components/community/blog/blog-info.component"
import BlogResults from "../components/community/blog/blog-results"
import { BlogIndex } from "../components/community/blog/blog.interface"
import Layout from "../components/layout"
import SEO from "../components/seo.component"
import { Path } from "../navigation/constants/routes"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import BlogFilters from "../components/community/blog/blog-filters"
import { ContentType } from "../constants/Query"
import { useFilteredContent } from "../helpers/get-filtered-content"
import Pagination from "../components/common/pagination.component"
import { AppFunctionComponent } from "../types"
import { MouseProvider } from "../hooks/use-mouse.hook"

export const StoriesWrapper = styled(Section)`
  grid-row-gap: 30px;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`

const ScrollAnchor = styled.span`
  display: block;
  margin-top: -130px;
  margin-bottom: 130px;
`

const BlogIndexPage: AppFunctionComponent<BlogIndex> = ({
  data: {
    posts,
    featuredPosts,
    meta,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
  pageContext: { pagination, pathPrefix },
}) => {
  const normalizedPosts = posts.nodes
  const normalizedFeaturedPosts = featuredPosts.nodes
  const firstFeaturedPost = normalizedFeaturedPosts.find(
    (post) =>
      post.postSlug ===
      "unplug-to-connect-a-bedroom-rule-to-strengthen-your-relationship"
  )

  const customNormalizedFeaturedPosts = firstFeaturedPost
    ? [
        firstFeaturedPost,
        ...normalizedFeaturedPosts.filter(
          (post) => post.postSlug !== firstFeaturedPost.postSlug
        ),
      ]
    : normalizedFeaturedPosts

  const {
    pending,
    items,
    pagination: filteredPagination,
    queryParams,
  } = useFilteredContent({
    contentType: ContentType.Blog,
    allItems: normalizedPosts,
    totalPages: pagination.total!,
    basePath: Path.Blog.toString(),
    slug: "",
    page: pagination.currentPage!,
    limit: 9,
  })

  const normalizedPagination = {
    currentPage: pagination.currentPage!,
    pagePrefix: Path.Blog.toString(),
    nextPage:
      pagination.currentPage! < pagination.total!
        ? pagination.currentPage! + 1
        : null,
    prevPage:
      pagination.currentPage! === 1 ? null : pagination.currentPage! - 1,
  }

  const paginationComponent = (
    <Pagination
      current={filteredPagination.page}
      rootUrl={filteredPagination.basePath}
      total={filteredPagination.totalPages}
      query={queryParams}
      scrollTo="latest-stories"
    />
  )
  return (
    <MouseProvider>
      <Layout
        topNavigationItems={topNavigationItems}
        footerNavigationItems={footerNavigationItems}
        promobarContent={promobarContent}
        masterPage
      >
        <SEO title="Community" {...meta} pagination={normalizedPagination} />
        <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
        <BlogInfo />
        <BlogChosenStories featuredPosts={customNormalizedFeaturedPosts} />
        <StoriesWrapper>
          <SearchWrapper>
            <SecondaryHeading renderAs={"h2"}>
              <ScrollAnchor id={"latest-stories"} />
              Latest stories
            </SecondaryHeading>
          </SearchWrapper>
          <BlogFilters
            basePath={pathPrefix + Path.Blog.toString()}
            oneFilterAtTime
          />
          <BlogResults
            pending={pending}
            pagination={paginationComponent}
            posts={items}
          />
        </StoriesWrapper>
      </Layout>
    </MouseProvider>
  )
}

export default BlogIndexPage

export const query = graphql`
  query BlogPostsPageQuery(
    $postsIds: [String!]!
    $featuredPostsIds: [String!]!
    $locale: String! = "en-US"
    $language: String!
  ) {
    meta: contentfulPageMeta(page: { eq: "community/blog" }) {
      ...PageMeta
    }
    posts: allContentfulBlogPost(
      filter: { id: { in: $postsIds }, node_locale: { eq: $locale } }
      sort: { fields: postOriginalPublishDate, order: DESC }
    ) {
      nodes {
        postSlug
        postTitle
        postOriginalPublishDate
        postOriginalPublishDateFormatted: postOriginalPublishDate(
          formatString: "MMM D, YYYY"
        )
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
        postFeaturedImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 800
            formats: WEBP
          )
          title
          description
        }
        postDiscourseId
      }
    }
    featuredPosts: allContentfulBlogPost(
      filter: { id: { in: $featuredPostsIds }, node_locale: { eq: $locale } }
      sort: { fields: postOriginalPublishDate, order: DESC }
    ) {
      nodes {
        postSlug
        postTitle
        postOriginalPublishDate
        postOriginalPublishDateFormatted: postOriginalPublishDate(
          formatString: "MMM D, YYYY"
        )
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
        postFeaturedImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 800
            formats: WEBP
          )
          title
          description
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
